import { Link, graphql, HeadProps } from "gatsby"
import * as React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import * as style from "../style/_index.module.scss"
import Seo from "../components/Seo"


type DataProps = {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
}  

const GalleryPage = ({ data, data: { site } }: PageProps<DataProps>) => {
    return (
        <main>
            <Header></Header>
            <section className={style.sectionGalleryWrap}>
                <div className={style.marginWrap}>
                    <h2>Gallery</h2>
                    <h3>coming soon</h3>
                </div>
            </section>
            <Footer></Footer>
        </main>
    )
}

export default GalleryPage
export const Head = () => <Seo title='ギャラリー' />;
   

export const query = graphql`
query GalleryPageQuery{
  site{
    siteMetadata{
      title
      description
    }
  }
}
`